import React, {useCallback, useEffect, useState} from "react";
import {logout, observeLoginStatus} from "./auth.client";
import {TwoFactorAuthSetupPage} from "./TwoFactorAuthSetupPage";
import {NewVerifyEmailPage} from "./NewVerifyEmailPage";
import {authStorage} from "./AuthStorage";
import {NotSignedInPage} from "./NotSignedInPage";
import {useInstantUpdater} from "../generic.hooks";
import {NewLoginPage} from "./NewLoginPage";
import {NewSignUpPage} from "./NewSignUpPage";
import {NewResetPasswordPage} from "./NewResetPasswordPage";
import {AuthCtxProvider} from "./AuthContextProvider";
import {PageLoading} from "../ui/Loading";
import {useCompanyId} from "../core";
import {getCompany} from "../company/company.client";

export function useAuth() {
  const [authState, setAuthState] = useState({ isAuthenticated: false, loading: true });
  const companyId = useCompanyId();

  const updateAuthState = useCallback((status: {loading: boolean, isAuthenticated: boolean})=>{
      if(status.isAuthenticated && !status.loading && companyId){
          let selectedCompanyForAuthedUser = authStorage.getCompany();
          // Selects the company supplied in the url.
          if(selectedCompanyForAuthedUser.id !== companyId){
              const authedUser = authStorage.getUser();
              const isAdminUser = authedUser.isAdmin();
              if(isAdminUser){
                  getCompany(companyId).then((company)=>{
                      console.info("Loading selected company to authStorage...");
                      authStorage.setCompany(company);
                  }).catch((error)=>{
                      console.error("Could not get company", error)
                  }).finally(()=>{
                      setAuthState(status)
                      return;
                  })
              } else {
                  // @TODO: In the case when user does not have access to the provided companyId, should we show access restricted view?
                  const authedUserCompanies = authedUser.data.companies;
                  authStorage.setCompany(authedUserCompanies?.find((c)=>c.id === companyId) ?? authStorage.getCompany());
                  setAuthState(status)
                  return;
              }

          } else {
              setAuthState(status)
              return;
          }
      } else {
          setAuthState(status)
          return;
      }
  }, [companyId])
  useEffect(() => {
    return observeLoginStatus(updateAuthState);
  }, []);
  return authState;
}

interface AuthProps {
  skipMailVerification?: boolean;
  skipRequireCompany?: boolean;
  skip2FA?: boolean;
  render?: () => JSX.Element;
  children?: JSX.Element;
  renderLoginPageIfNotAuthenticated?: boolean
  renderSignUpPageIfNotAuthenticated?: boolean
  renderResetPasswordPageIfNotAuthenticated?: boolean
}

export const AuthCtxWrapper = ({children}: {children: React.ReactNode}) => {
    const [, reloadAuth] = useInstantUpdater();
    const handleLogout = useCallback(() => {
        logout()
            .catch(console.error)
    }, [reloadAuth])
    return <AuthCtxProvider reloadAuth={reloadAuth} logout={handleLogout} >{children}</AuthCtxProvider>
}

export function NewAuthWrapper(props: AuthProps): JSX.Element {
    const [, reloadAuth] = useInstantUpdater();
    const handleLogout = useCallback(() => {
            logout()
                .catch(console.error)
    }, [reloadAuth])
    return <AuthCtxProvider reloadAuth={reloadAuth} logout={handleLogout} ><NewAuthWrapperImpl {...props} /></AuthCtxProvider>;
}

function NewAuthWrapperImpl({render, children, skipMailVerification, skipRequireCompany, skip2FA, renderLoginPageIfNotAuthenticated, renderSignUpPageIfNotAuthenticated, renderResetPasswordPageIfNotAuthenticated}: AuthProps): JSX.Element {
    const { isAuthenticated, loading } = useAuth()
    useEffect(() => {
        if (render && children) console.error("You shouldn't use render and children at the same time")
        else if (!render && !children) console.error("You should use render or children")
    }, [render, children])

    if (loading) {
        return <PageLoading />
    }
    if (!isAuthenticated) {
        if(renderLoginPageIfNotAuthenticated){
            return <NewLoginPage />;
        } else if(renderSignUpPageIfNotAuthenticated){
            return <NewSignUpPage />;
        } else if(renderResetPasswordPageIfNotAuthenticated){
            return <NewResetPasswordPage />
        } else {
            return <NotSignedInPage />;
        }
    }

    if (!isMailVerified(skipMailVerification) || (isMailVerified(skipMailVerification) && !isInACompany(skipRequireCompany))) {
        return <NewVerifyEmailPage />;
    }

    if (skipRequireCompany && !isInACompany(skipRequireCompany)) {
        return <NotSignedInPage />;
    }

    if (!is2FAEnabled(skip2FA)) {
        return <TwoFactorAuthSetupPage />;
    }

    return render ? render() : <>{children}</>;
}

function isMailVerified(skipMailVerification: boolean | undefined = false): boolean {
  return skipMailVerification === true || authStorage.getUser().emailVerified;
}

function isInACompany(skipRequireCompany: boolean | undefined = false): boolean {
  return skipRequireCompany === true || !!authStorage.getUser().company;
}

function is2FAEnabled(skip2FA: boolean | undefined = false): boolean {
  return skip2FA === true || !authStorage.getUser().requiresMultiFactor || authStorage.getUser().twoFactorAuthenticated;
}


